.ma-table-wrapper {
    .ma-table-d {
        //overflow-x: scroll;

        .ma-table-tbl {
            // overflow-x: scroll;
        }
    }


    .col-header {
        width: 200px;
    }
}

.q-wise-report {
    .ma-table-d {
        //overflow-x: scroll;

        .ma-table-tbl {
            // overflow-x: scroll;
        }
    }


    .col-header {
        width: 200px;
    }

    .col-answer {
        width: 120px;
    }
}

.q-s-wise-report {
    .ma-table-d {
        //overflow-x: scroll;

        .ma-table-tbl {
            // overflow-x: scroll;
        }
    }


    .col-header {
        width: 200px;
    }

    .col-answer {
        min-width: 210px;
    }
}

@media print {
    @page{
        margin: 0;
        size: A4 portrait;
    }
    body {
        margin: 0.65cm;
        background-color: #ffffff;
      }
    .progress-report {
        .logo-with-details {
            // width : 100%;
            // height: 100%;
        }
        .remarks-height{
            height: 50px;
        }
        .stud-details{
            padding: 20px;
            margin-top: -20px
        }
        .span-font-size{
            font-size: 12px;
        }
        .list-of-tables {
            .test-table{
                .test-head{
                    font-size: 12px;
                }
                .test-data{
                    font-size: 10px;
                }
            }
        }
        .graph-head{
            font-size: 12px;
            margin-bottom: 0px;
            margin-top: 0px;
        }
        .graph-height{
            height: 150PX;
            margin: -20px 0px 0px 0px;
        }
    }    
}