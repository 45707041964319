.exam-correction {

    .p-marks {

        font-weight: 600;
        font-size: 20px;
        font-family: Poppins;
    }
}


.scoring-preview {

    .sc-title {
        color: var(--primary-color);
        font-family: Poppins;
    }

    .sc-name {
        font-family: Poppins;
        color: #4d4d4d;
        text-transform: capitalize;
    }

    .sc-marks {
        padding: 5px 10px;
        // margin-left: 30px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        height: 36px;
        border-radius: 7px;
        width: 80px;
        font-family: Poppins;
        text-align: center;
    }

    .sc-outof {
        margin-left: 5px;
        margin-top: 6px;
        font-weight: bold;
        font-family: Poppins;
    }

    .q-unmarked {
        color: var(--primary-color);
    }


    .sc-mark-c {
        margin: 6px;

    }

    .sc-mark-l {
        margin-top: 4px;
        width: 70px;
    }

    .sc-mark-total {
        margin-top: 4px;
        width: 120px;
    }
}


.ma-cr-pagin {
    margin-top: 16px;

    .cr-pagi-l {
        padding: 2px 5px;
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
    }

    .cr-pagi-r {
        padding: 2px 10px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }

    .cr-pagi-v {
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 6px 12px;
        // border-radius: 10px;

    }
}


.tui-colorpicker-clearfix {

    overflow: auto;

    .tui-colorpicker-palette-button {

        height: 20px;
        width: 20px;
    }

  
  
}


li[tooltip-content="Delete"] {
    display: none !important;
}

li[tooltip-content="Reset"] {
    display: none !important;
}
li[tooltip-content="DeleteAll"] {
    display: none !important;
}


li[tooltip-content="History"] {
    display: none !important;
}

li[tooltip-content="Undo"] {
    display: none !important;
}
li[tooltip-content="Redo"] {
    display: none !important;
}
.student-comments {
    font-family : ma-poppins;
    font-size: 14px;
    margin-left: 10px;
}



.tui-image-editor-item .tui-image-editor-icpartition {
    display: none !important;

}