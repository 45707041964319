.creat-vclass {
    font: normal normal normal 23px/34px Poppins;
    letter-spacing: 0.14px;
    color: var(--theme-45);
    opacity: 0.8;
    margin-bottom: 0px;
    margin-left: 16px;
}



.class-detials {
    padding: 20px 40px;

    .d-title {
        font: normal normal normal 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: #9C9C9C;
        margin-bottom: 0px;
        // margin-top: 0px;
    }

    .d-value {
        font: normal normal 600 16px/20px Poppins;
        letter-spacing: 0px;
        color: #545454;
        margin: 0px 0px;

    }
}


.success-dialog {
    min-width: 400px;
    overflow: hidden;
    max-width: 400px;

    .img-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 72px;
    }

    .success-title {
        text-align: center;
        font: normal normal bold 36px/49px Nunito Sans;
        letter-spacing: 0px;
        color: #7E7E7E;
        margin: 0px;
    }

    .success-msg {
        text-align: center;
        font: normal normal 600 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;

        margin: 0px;
        margin-top: 10px;
    }

    .title-s1 {
        text-align: center;
        font: normal normal bold 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;
        margin: 0px 0px 5px 0px;
    }

    .desc-s1 {
        text-align: center;
        font: normal normal normal 14px/19px Nunito Sans;
        letter-spacing: 0px;
        color: #888888;
        margin: 0px;
    }



}

.success-dialog2 {
    min-width: 530px;
    overflow: hidden;
    max-width: 530px;

    .img-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 72px;
    }

    .success-title {
        text-align: center;
        font: normal normal bold 36px/49px Nunito Sans;
        letter-spacing: 0px;
        color: #7E7E7E;
        margin: 0px;
    }

    .success-msg {
        text-align: center;
        font: normal normal 600 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;

        margin: 0px;
        margin-top: 10px;
    }

    .title-s1 {
        text-align: center;
        font: normal normal bold 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;
        margin: 0px 0px 5px 0px;
    }

    .desc-s1 {
        text-align: left;
        font: normal normal 600 14px/19px Nunito Sans;
        letter-spacing: 0px;
        color: #545454;
        margin: 0px;
    }



}
.attention-dialog {
    min-width: 260px;
    overflow: hidden;
    max-width: 260px;

    .img-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 72px;
    }

    .success-title {
        text-align: center;
        font: normal normal bold 36px/49px Nunito Sans;
        letter-spacing: 0px;
        color: #7E7E7E;
        margin: 0px;
    }

    .success-msg {
        text-align: center;
        font: normal normal 600 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;

        margin: 0px;
        margin-top: 10px;
    }

    .title-s1 {
        text-align: center;
        font: normal normal bold 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;
        margin: 0px 0px 5px 0px;
    }

    .desc-s1 {
        text-align: center;
        font: normal normal normal 14px/19px Nunito Sans;
        letter-spacing: 0px;
        color: #888888;
        margin: 0px;
    }



}

.resource-attach {
    box-shadow: 0px 2px 3px #00000029;
    border: 0.5px solid #BEBDBD;
    border-radius: 5px;
    padding: 20px;

    .attach-w {
        min-height: 160px;
    }
}

.virtual-resources {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 16px;
}



.class-details {

    .cls-title {
        font: normal normal 600 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: #8F8F8F;
        width: 80px;
        display: inline-block;
    }

    .cls-value {
        font: normal normal normal 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: #333333;
    }
}

.class-more-details {
    padding: 20px;


    .cmd-label {
        font: normal normal 600 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: #8F8F8F;

    }


    .cmd-value {
        font: normal normal normal 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: #333333;
    }
}