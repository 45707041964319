.bulk-upload {


    .spread-title {


        text-align: left;
        font: normal normal normal 14px/21px Poppins;
        font-weight: 600;
        letter-spacing: 0px;
        color: #1E1E1E;
    }

    .select-file {
        margin-left: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
        font: normal normal 600 13px/18px Nunito Sans;
        letter-spacing: 0px;
        color: var(--primary-color);
        padding: 5px 10px;
    }


    .refresh-btn {
        float: right;
    }

    .file-attach-name {
        padding: 0.35rem 0.5rem;
        background: var(--theme-100) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px var(--shadow-85);
        border: 0.5px solid var(--theme-85);
        border-radius: 5px;
        // height: 20px;
        height: 30px;
        min-width: 200px;
        display: inline-block;
        margin: 0px;
    }
}