
.e-paper {
    background-color: white;
    margin: 0px 0px;
    padding: 0px 30px;
    font-size: 13px;
    .e-instructions {}

    .school-title {
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }

    .school-address {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }


    .e-header {
        border-style: double;
        border-right: none;
        border-left: none;
        height: 56px;
        font-family: 'Poppins', sans-serif;
        // border-top: 5px double black;
        // border-bottom:1px double black; 


        .e-date {
            font-weight: bold;
            font-size: 13px;
            font-family: 'Poppins', sans-serif;     
        }
    }

    .subject-name {
        text-align: center;
        text-decoration: underline;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;



    }

    .paper-download {

        // font-family: 'Poppins', sans-serif;
        font-family: 'Times New Roman', Times, serif;

        .question-wrapper {

            margin: 10px;

            .num-answer {
                border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                margin-left: 50px;
                width: 200px;
                margin-top: 26px;
                margin-bottom: 0px;

            }

            .question {
                font-size: 13px;
                margin: 0px 15px 0px 15px;
                font-weight: bold;

                span.qNo {
                    margin-right: 8px;
                }
            }

            .option-w {
                width: 50%;
                float: left;

                .option {
                    margin: 4px 15px 4px 50px;
                    font-size: 13px;
                }
            }

            .q-attach {
                margin-left: 100px;
                padding: 10px;
                max-width: 20%;
            }

            .o-attach {
                margin-left: 60px;
                padding: 10px;
                // max-height: 200px;
                max-width: 40%;
            }
        }
    }
}

.exam-instruction{
    margin-top: 24px;

}

.e-exam-name {
    text-align: center;
    margin-top: -43px;
    font-size: 18px;
    font-weight: bold;

    span {

        border: 1px solid black;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 5px 10px;
    }
}


.sp-right {
    float: right;
}

.sp-no-pm {
    margin: 0px;
    padding: 0px;
}



.sp-clearFix {
    clear: both;
}


.sp-mt40 {
    margin-top: 40px !important;
}


.e-hr {
    color: black;
}



.sp-ml10 {

    margin-left: 10px;
}