.resources-list {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
    margin: 20px 20px;
    padding: 20px 40px;


    .r-title {
        font: normal normal normal 23px/34px Poppins;
        letter-spacing: 0.14px;
        color: #171725;
        margin-top: 0px;
    }

    .res-lable {
       // font: normal normal normal 19px/29px Poppins;
        letter-spacing: 0px;
        color: #545454;
        margin-right: 30px;
        //margin-top: 20px;
        top: 10px;
        text-transform: capitalize;

    }
}