.register-verify {

    margin: auto;
    width: 40%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 14px;
    padding: 20px;
    margin-top: 20vh;
    font-family: Nunito Sans;

    .p-txt {

        font-family: Nunito Sans;
    }

    h2 {
        text-align: center;
    }
}