.subject-add-dialog {
    min-width: 340px;
}

.w-100 {
    width: 100%;
}

.createPlanner-table {
    table {
        table-layout: initial;

        thead.p-datatable-thead {
            tr {
                th:last-child {
                    text-align: center;
                }
            }
        }

        .action-btn {
            list-style: none;
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: center;

            li {
                margin: 0 9px;
            }
        }
    }
}


// .p-from-group {
//     margin-bottom: 10px;

//     label {
//         margin-bottom: 4px;

//         .ma-required {
//             margin-left: 8px;
//         }
//     }

//     .p-form-control {
//         display: block;
//         width: 100%;
//     }
// }

.dropdown-grid {
    .p-align-items-center {
        align-items: center;
    }

    .p-dropdown {
        width: 100%;
    }

    .ma-required-mark {
        margin-right: 0;
    }
}

.doubt-tab-block {
    padding: 18px 0;

    .dobut-btn {
        margin-right: 12px;
    }
}

.planner-table-wrap {
    thead.p-datatable-thead {
        tr {
            th:last-child {
                width: 40px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .p-datatable-tbody {
        tr {
            .ckEditor-container {



                &:not(.hide) {
                    .cke_chrome {
                        box-shadow: 0px 3px 6px var(--shadow-85);
                        border: 0.5px solid var(--theme-85);
                        border-radius: 5px;
                    }
                }

                &.hide {
                    position: relative;
                    height: 32px;
                    cursor: pointer;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        background: var(--theme-100) 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px var(--shadow-85);
                        border: 0.5px solid var(--theme-85);
                        border-radius: 5px;
                    }

                    .cke_chrome {
                        border: none;
                    }

                    .cke_top,
                    .cke_bottom {
                        display: none;
                    }
                }
            }

            td:last-child {
                padding-left: 0;
                padding-right: 0;

                button.collaps-btn {
                    padding: 3px;
                    background: transparent;
                    border: none;
                    color: #bababa;
                    display: block;
                    margin: auto;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    font-size: 14px;

                    &.up {
                        transform: rotate(180deg);
                    }

                    &.down {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

}



.create-class-t {
    font: normal normal bold 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
}


.planner-preview {

    .preview-title {
        font: normal normal normal 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: #848484;
    }

    .preview-value {
        margin-top: 5px;
        font: normal normal 600 11px/17px Poppins;
        letter-spacing: 0px;
        color: #545454;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}