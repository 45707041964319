.inputtext-style{
    padding: 0.3rem 0.5rem;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    margin-left: 12px;
    width: 90%;
}
.toolbar {
    margin-left: 8%;
}