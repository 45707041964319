.info-txt {
    text-align: left;
    //font: normal normal 800 18px/24px Nunito Sans;
    font-family: Nunito Sans;
    font-weight: 800;
    letter-spacing: 0px;
    color: #171725;
}

.resourse-add {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    width: 230px;
    height: 123px;
    align-items: center;
    text-align: center;
    overflow: hidden;
    float: left;
    margin-right: 30px;

    .type-title {
        font: normal normal bold 13px/18px Nunito Sans;
        letter-spacing: 0px;
        color: #4E4E4E;
        margin: 0px;
        padding: 10px;
        text-align: left;
        background-color: #E6E6E6;
        overflow: hidden;
    }


    .add-ico {
        padding: 15px;
        margin-top: 13px;

        span.add-ic {
            padding: 10px;
            padding-bottom: 14px;
            background-color: var(--primary-color);
            border-radius: 10px;

            i {
                color: white;
                padding: 6px;
                padding-right: 7px;
                border-radius: 50%;
                background-color: var(--theme-65);
                //opacity: 0.7;
                font-size: 10px;
            }


        }

        .add-txt {
            margin-left: 10px;
            font: normal normal 600 10px/16px Poppins;
            letter-spacing: 0px;
            color: var(--primary-color);
        }
    }
}



.add-a-resource-dialog {
    .p-dialog-header {
        //padding: 0px;
        text-align: center;

        .p-dialog-title {
            color: black;
        }
    }





    .add-a-resource {
        .title {
            margin-top: 0px;
            font: normal normal 800 29px/39px Nunito Sans;
            letter-spacing: 0px;
            color: #171725;
            text-align: center;
            padding-top: 10px;
        }

        .section {
            overflow: hidden;
            //margin: 0px 30%;
            width: 260px;
            padding: 30px;
            margin: auto;
            height: 340px;
            background: transparent linear-gradient(180deg, #4A95F9 0%, #1322E9 100%) 0% 0% no-repeat padding-box;
            border-radius: 41px;

            .add-ico {
                padding: 30px;
                margin-top: 40px;
                align-items: center;
                text-align: center;

                span.add-ic {
                    padding: 32px;
                    //padding-bottom: 14px;
                    opacity: 0.9;
                    border-radius: 20px;

                    i {
                        color: white;
                        padding: 6px;
                        padding-right: 7px;
                        border-radius: 50%;
                        //  background-color: var(--theme-65);
                        //opacity: 0.7;
                        font-size: 16px;
                        font-weight: bold;
                    }


                }

                span.add-ic1 {
                    background-color: #4A95F9;
                }

                span.add-ic2 {
                    background-color: #F9834A;
                }

                .add-txt {
                    margin-left: 10px;
                    font: normal normal 600 10px/16px Poppins;
                    letter-spacing: 0px;
                    color: var(--primary-color);
                }
            }

            .section-text {
                padding: 20px;
                text-align: center;
                font: normal normal 600 20px Poppins;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-shadow: 0px 3px 6px #00000029;
            }

        }

        .section-1 {
            background: transparent linear-gradient(180deg, #4A95F9 0%, #1322E9 100%) 0% 0% no-repeat padding-box;
            border-radius: 41px;
        }

        .section-2 {
            background: transparent linear-gradient(180deg, #F9834A 0%, #E94D13 100%) 0% 0% no-repeat padding-box;
            border-radius: 41px;
        }


        .preview-c {

            text-decoration: underline;
            font: normal normal normal 15px/20px Nunito Sans;
            letter-spacing: 0.51px;
            color: #333333;
            opacity: 0.58;

            span {
                cursor: pointer;
            }
        }

        .resources-list {
            padding-left: 5%;
            padding-right: 5%;


            .tbl-title {

                font: normal normal bold 20px/27px Nunito Sans;
                letter-spacing: 0.68px;
                color: #333333;
                text-shadow: 0px 2px 3px #00000029;
            }
        }

        .upload-resource {
            padding-left: 3%;
            padding-right: 3%;

            .title {
                text-align: left;
                font: normal normal 800 18px/24px Nunito Sans;
                letter-spacing: 0px;
                color: #171725;
            }

            .select-file {
                margin-left: 10px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid var(--primary-color);
                border-radius: 7px;
                font: normal normal 600 13px/18px Nunito Sans;
                letter-spacing: 0px;
                color: var(--primary-color);
                padding: 5px 10px;
                margin-top: 20px;
            }

            .up-btn {
                top: 4px;
                margin-top: 24px;
            }


            .third-party {
                margin-top: 30px;
                padding: 20px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;


                .title {
                    font: normal normal 800 18px/24px Nunito Sans;
                    letter-spacing: 0px;
                    color: #171725;
                }
            }


        }
    }


}



.content-more {}




.cotent-player {
    height: 100%;

}


.preview-text {

    span {
        margin-left: 10px;
        text-decoration: underline;
        font: normal normal normal 15px/20px Nunito Sans;
        letter-spacing: 0.51px;
        color: #333333;
        cursor: pointer;
        opacity: 0.58;
    }
}


.edit-content {

    .select-file {
        // margin-left: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
        font: normal normal 600 13px/18px Nunito Sans;
        letter-spacing: 0px;
        color: var(--primary-color);
        padding: 5px 10px;
        margin-top: 20px;
    }
}



.cont-btn {

    height: 28px;
    //top: 10px;
}