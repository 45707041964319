// .custom-input2 {
//     border: 1px solid red;
//     padding: 6px;
//     background: var(--theme-100) 0% 0% no-repeat padding-box;
//     box-shadow: 0px 3px 6px var(--shadow-85);
//     border: 0.5px solid var(--theme-85);
//     border-radius: 5px;

//     .left {
//         //  border-right: 1px solid var(--shadow-60);
//         padding: 0px 4px;
//         color: var(--shadow-53);
//         font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//     }

//     .right {
//         color: var(--theme-45);
//         font-size: 1rem;
//         font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//         width: 40px;
//     }

//     .input-text {
//         border: none;
//         font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//         width: 70%;
//         // width: 56%;
//         outline: none;
//         margin-top: -2px;
//         //padding-top: 2px;
//         padding-left: 6px;
//         font-size: 1rem;
//         color: var(--shadow-53);
//     }
// }


.password-input {
    //border: 1px solid red;
    //padding: 8px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    // .left {

    //     padding: 0px 4px;
    //     color: var(--shadow-53);
    //     font-family: inherit;
    // }

    // .left-b {
    //     border-right: 1px solid var(--shadow-60);
    // }

    .right {
        color: var(--primary-color);
        font-size: 1rem;
        font-family: inherit;

    }

    .input-text {
        padding: 8px;
        border: none;
        font-family: inherit;
        // width: 80%;

        // width: 56%;
        outline: none;
        margin-top: -2px;
        //padding-top: 2px;
        padding-left: 6px;
        font-size: 1rem;
        color: var(--shadow-53);
    }

    .eye-icon {
        padding: 6px;
    }
}



.custom-password-w {
    width: 100%;
    overflow: hidden;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;

    .custom-right-icon {
        width: 24px;
        float: right;
    }


    .custom-left-input {
        margin-right: 24px;
    }



    .ma-eye-icon {
        .ma-icon-svg {
            margin-top: 5px;
        }
    }

    .ma-eye-icon:hover {
        cursor: pointer;
    }

    input {
        border: none;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1rem;
        color: var(--shadow-53);
        background: var(--theme-100);
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        -webkit-appearance: none;
        appearance: none;
        padding-bottom: 5px;
        padding: 0.35rem 0.5rem;

    }

}




.otp-verify-txt {
    color: rgba(0, 0, 0, 0.8);
    
    font-weight:600;


}