.report-c1 {
    background-color: #ffffff;

    .report-title-c {
        //  margin-top: 20px;
    }




    .report-title1 {
        margin-top: 30px;
        text-align: left;
        font: normal normal bold 16px/20px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        margin-bottom: 0px;
        opacity: 1;
    }

    .report-title2 {
        text-align: left;
        margin-top: 40px;
        margin-bottom: 0px;
        font: normal normal bold 16px/20px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
    }

    .report-info {
        text-align: left;
        font: normal normal bold 16px/20px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
    }

    .report-title {
        text-align: left;
        font: normal normal bold 18px/24px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
    }

    .report-exam-name {
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
        text-transform: capitalize;
    }

    .report-stats {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 4px 6px 6px #0000000a;
        border: 1px solid #caced5;
        opacity: 1;
        height: 80px;
        // margin: auto;
        //  max-width: 80%;
        border-radius: 10px;
        overflow: hidden;
        padding: 10px 2px;
        /* width: 183px; */
    }

    .stats-icon {
        width: 55px;
        height: 55px;

        opacity: 1;
        border-radius: 50%;

        .stats-img1 {
            padding: 14px;
        }

        .stats-img2 {
            padding: 21px 15px;
        }

        .stats-img3 {
            padding: 14px 18px;
        }

        .stats-img4 {
            padding: 14px;
        }
    }

    .stat-title {
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
        text-align: left;
        font: normal normal normal 15px/25px Roboto;
        margin-top: 0px;

        span {
            //  float: right;
            text-align: left;
            font: normal normal bold 14px/25px Source Sans Pro;
            letter-spacing: 0px;
            color: #3cc480;
            opacity: 1;
            margin-left: 2px;
        }
    }

    .stats-icon1 {
        background: #4981fd57 0% 0% no-repeat padding-box;
    }

    .stats-icon2 {
        background: #efe3e7 0% 0% no-repeat padding-box;
    }

    .stats-icon3 {
        background: #4981fd57 0% 0% no-repeat padding-box;
    }

    .stats-icon4 {
        background: #4dcef257 0% 0% no-repeat padding-box;
    }

    .stat-count {
        font: normal normal bold 25px/33px Source Sans Pro;
        letter-spacing: 0px;
        color: #3cc480;
        opacity: 1;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .stat-count1 {
        //color: #3cc480;
        color: #3a49be;
    }

    .stat-count2 {
        // color: #ff4141;
        color: #ff0000;
    }

    .stat-count3 {
        color: #3a49be;
    }

    .stat-count4 {
        //color: #1685a3;
        color: #10abd5;
    }

    .btn-r {
        display: block;
        padding: 8px 20px 8px 30px;
        border-radius: 7px;
        font: normal normal medium 17px/26px Roboto;
        letter-spacing: 1.02px;
        color: #ffffff;
        opacity: 1;
        text-transform: capitalize;
        font-size: medium;
        float: right;
        width: 80%;
        height: 47px;
        margin-bottom: 4px;
        //margin: auto;

        .arrow-icon {
            margin-left: 26px;
        }
    }

    .btn-r1,
    .btn-r1:hover,
    .btn-r1:focus {
        background-color: #2b42cf;
    }

    .btn-r2,
    .btn-r2:hover,
    .btn-r2:focus {
        background-color: #34315d;
    }

    .btn-r3,
    .btn-r3:hover,
    .btn-r3:focus {
        background-color: #778ed7;
    }

    .btn-r:not(:first-child) {
        margin-top: 10px;
    }
}

.time-management-c {
    border: 1px solid #707070;
    border-radius: 10px;

    // width: 90%;
    .time-management {
        // .time-c {
        //     position: relative;
        // }

        margin: 0px 10px;

        .exam-tbl {

            margin-top: -1px;
            color: #6a6a6a;
            font-weight: 600;
            font-size: 12px;
            text-transform: capitalize;
            border-collapse: collapse !important;
            border-radius: 18px 18px 0px 0px !important;
            overflow: hidden !important;
            // max-width: 80%;

        }


        @media print {
            .exam-tbl {

                max-width: 110% !important;

                min-width: 110% !important;

            }
        }

        table.exam-tbl th {
            /* background-color: #F5F6FA; */
            padding: 10px 5px;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            border: 1px solid rgba(0, 0, 0, 0.2);
            // padding: 17px;
            // border-left: 1px solid rgba(0, 0, 0, 0.2);
            // border-right: 1px solid rgba(0, 0, 0, 0.2);
        }

        .time-m-title {
            font: normal normal normal 21px/20px Roboto;
            letter-spacing: 0px;
            color: #4d4f5c;
            opacity: 1;
            border-collapse: collapse;
        }

        .t-highlights {
            z-index: 10;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            border-radius: 16px 14px 16px 16px;
            border: 1px solid rgba(0, 0, 0, 0.2);

            .time-m-stats {
                text-align: center;
                padding: 16px 15px;
                margin: 0px;
                display: inline-flex;
                align-items: center;
                color: #6a6a6a;
                font-size: 12px;

                .count {
                    font-size: 20px;
                    font-weight: bold;
                    margin-left: 2px;
                }

                .count-0 {
                    color: #17b705;
                }

                .count-1 {
                    color: #ffd710;
                }

                .count-2 {
                    color: #eb5037;
                }

                .count-3 {
                    color: #0540b7;
                }

                .count-4 {
                    color: #cf1d4e;
                }
            }
        }

        .exam-tbl tr:first-child th {
            padding: 0px !important;
            border-block-end-color: white !important;
            border-block-start-color: white !important;
            position: relative !important;

        }
    }
}

/* .time-management table.exam-tbl, */

//.time-management table.exam-tbl,
//.time-management table.exam-tbl th,
.time-management table.exam-tbl td {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-collapse: collapse;
    text-align: center;
}

.time-management th,
.time-management table.exam-tbl td {
    padding: 5px 10px;
    /* background-color: white; */
    font-size: 12px;
}

.time-management table.exam-tbl td {
    font-weight: 500;
    padding: 10px;
}

.time-management table.exam-tbl .phone-number {
    color: #00118b;
    font-weight: bold;
}

.subjects-table {
    border: 1px solid #34315d;
    width: 100%;
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;

    tr:nth-child(1) {
        background-color: #34315d;
    }

    tr th:first-child {
        padding-left: 20px !important;
    }

    th {
        color: white;
        // text-align: center;
    }

    th:not(:first-child) {
        text-align: center;
    }

    td:not(:first-child) {
        text-align: center;
    }

    td {
        color: gray;
    }

    td,
    th {
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-transform: capitalize;
        border-collapse: collapse;
        overflow: hidden;
        // overflow: hidden;
    }

    tr td:first-child {
        padding-left: 20px;
    }
}

.comparative-analysis {
    margin-top: 20px 10px 0px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    //display: inline-block;
    border-radius: 15px;

    .comparative-title {
        font-size: 16px;
        padding-top: 20px;
        color: #484848;
        text-align: center;
        font-weight: bold;
        text-transform: capitalize;
    }

    .comparativ-bar {
        height: 300px;
        padding: 20px;
        margin-top: -70px;
    }
}

.breakdown-analysis {
    margin: 20px 0px;

    .b-title {
        // font-size: 19px;
        //font-weight: 500;
        letter-spacing: 0px;
        opacity: 0.66;
        font: normal normal bold 19px/26px Nunito Sans;
    }

    .b-times {
        color: #0030ef;
        font-size: 12px;
    }

    .b-c-title {
        //  text-align: center;
        //  display: inline-flex;
        align-items: center;
        color: #8a8a8a;
        font-size: 14px;
        margin-top: 10px;
    }

    .b-count {
        float: right;
        font: normal normal bold 26px/35px Nunito Sans;
        letter-spacing: 0px;
        color: #252525;
        opacity: 1;
    }

    .attempted-c {
        color: #252525;
    }

    .overtime-c {
        color: #2c67e8;
    }

    .perfect-c {
        color: #6bf9e2;
    }

    .wasted-c {
        color: #16bcea;
    }
}

// .time-slot-c {
//     margin: 20px 0px;

.time-slot-w {
    //  width: 50%;
    // float: left;

    .time-slot {
        border: 1px solid grey;
        border-radius: 7px;
        opacity: 1;
        font: normal normal normal 12px/16px Nunito Sans;
        letter-spacing: 0px;
        color: grey;
        opacity: 1;
        padding: 8px;
        margin: 10px;
        margin-left: 0px;
        width: 120px;
        height: 32px;

        .t-active {
            background-color: #00ff55;
            border-color: #00ff55 !important;
        }

        .t-btn {
            width: 8px;
            height: 8px;
            border: 1px solid gray;
            border-radius: 50%;
            display: inline-block;
        }
    }

    .t-b-active {
        color: #0030ef;
        border-color: #0030ef;
    }
}

//}

.line-chart {
    margin-top: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 4px 10px 6px #0000000a;
    border: 1px solid #caced5;
    opacity: 1;
    text-align: center;
    border: 1px solid grey;
    border-radius: 18px;
    overflow: hidden;


    .line-chart-c {
        margin-top: 20px;
        height: 300px;
        margin-bottom: 20px;
    }

    .form-control {
        //  width: 100%;

        .text-field {
            margin: 30px 10px;
            width: 240px;
            margin-bottom: 0px;
            height: 2rem;
        }

        .MuiMenuItem {
            font: normal normal normal 10px/14px Nunito Sans;
            letter-spacing: -0.24px;
            color: #000000;
            opacity: 0.6;
            padding: 3px 16px;


        }
    }
}


@media print {
    .page-break {
        page-break-after: always;
    }
}