.chat-topnar {
  //box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  width: 100%;
  padding: 14px 14px 8px;
  z-index: 1;
  box-shadow: 0px 3px 5px #00000029;
}

.more-options {
  background-color: white;
  box-shadow: 0px 5px 10px #00000029;
  border: 1px solid #dbdbdb;
  border-radius: 6px 0px 6px 6px;
  z-index: 2;

  ul {
    list-style-type: none;
    z-index: 2;
    color: #929292;
    margin: 0px;

    li {
      padding: 8px;
    }
  }
}

.ma-1 {
  float: left;
}

.ask-a-doubt {
  //  background-color: var(--body-background);
  padding: 20px;

  .doubts-tabs {
    text-align: center;

    .doubts-tab-w {
      padding: 0px 10px;
    }

    p {
      font-size: 16px;
      letter-spacing: 0.09px;
      color: var(--shadow-60);
      font-weight: 600;
      margin-bottom: 10px;

      padding: 7px;
    }
  }
}

.tab-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px 10px 0px 0px;

  //border-bottom: 3px solid var(--primary-color);
}

.tab-active-p {
  //  background: #ffffff 0% 0% no-repeat padding-box;
  //box-shadow: 0px 0px 6px #00000029;
  // border-radius: 10px 10px 0px 0px;

  border-bottom: 4px solid var(--primary-color);
}

.conversation-c {
  margin-left: 20px;
  margin-right: 20px;
  // margin-top: 48px;
  box-shadow: 0px 2px 4px #00000029;

  .select-profile-c {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 6px;
    display: inherit;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);

    .select-profile {
      top: 40%;
      text-align: center;
      width: 50%;
      margin: auto;
      position: absolute;
    }

    .select-profile-txt {
      margin-top: 15px;
      letter-spacing: 0px;
      font-weight: bold;
      font: normal normal normal 18px/27px Poppins;
      letter-spacing: 0px;
      color: #3b3b3b;
      opacity: 0.45;
    }
  }
}

.time {
  float: right;
  font: normal normal normal 10px/14px Nunito Sans;
  letter-spacing: 0px;
  color: #afafaf;
}

.c-top-bar {
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 6px 6px 0px 0px;

  display: flex;

  .prfl-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .prfl-name {
    letter-spacing: 0px;
    color: var(--primary-color);
    margin: 0px;
    font: normal normal 600 15px/20px Nunito Sans;
    letter-spacing: 0px;
    text-transform: capitalize;
  }

  .prfl-grade {
    margin: 0px;
    font: normal normal normal 8px/11px Nunito Sans;
    letter-spacing: 0px;
    color: #AFAFAF;
  }
}

.chat-window {
  max-height: calc(100vh - 368px);
  min-height: calc(100vh - 368px);
  overflow-y: auto;
  background-color: #f8f8f8;
  padding: 10px;

  .conversation-img {
    width: 50%;
  }

  .conversation-video {
    width: 50%;
  }

  .conversation-attachment {
    background: var(--primary-color) 0 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border: 0.5px solid var(--primary-color);
    width: 100%;
    overflow: hidden;
    padding: 6px;
    margin: 10px 0px 10px 10px;
    width: 58%;

    .ma-attach-icon {
      width: 14px;
    }

    p {
      margin: 0px;
      padding: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      font: normal normal 600 11px/15px Nunito Sans;
      letter-spacing: -0.26px;
      color: #494848;
      opacity: 1;
    }
  }
}

.closed-chat {
  height: 56px;
  border-top: 0.5px solid #d9d9d9;

  p {
    margin-top: 10px;
    text-align: center;
    font: normal normal 600 18px/24px Nunito Sans;
    letter-spacing: 0px;
    color: #3b3b3b;
  }
}

.ma-right {
  float: right;
  text-align: right;
  border-radius: 8px 0px 8px 8px;
}

.ma-left-b {
  background-color: var(--primary-color) !important;

  .message-text {
    color: #ffffff !important;
  }

}

.ma-left1 {
  color: white;
  float: left;
  border-radius: 0px 8px 8px 8px;
}

.ma-clearFix {
  clear: both !important;
}

.chat-alert-c {

  text-align: center;
  width: 50%;
  margin: auto;
  height: 40px;

  .chat-alert {
    background: var(--shadow-60) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: white;
    padding: 8px;
  }
}

.chat-alert-danger {
  text-align: center;
  width: 50%;
  margin: auto;
  clear: both;
  background: #f99292 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 4px;

  .chat-alert {

    font-family: Nunito Sans;
    color: white;
    padding: 10px;
  }
}

//.message-c {
.message-text-c {
  background: #efefef 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #0000001f;
  max-width: 60%;
  padding: 4px 10px;
  margin-bottom: 10px;
  position: relative;

  .message-text {
    margin: 0px;
    font-weight: 600;
    overflow: hidden;
    word-wrap: break-word;
    width: 100%;
    font: normal normal 600 14px/24px Nunito Sans;
    letter-spacing: 0px;
    color: #343434;
    //border: 1px solid black;
  }
}

//.message-c {
.message-buttons-c {
  padding: 4px 10px;
  margin-bottom: 10px;

  .chat-button {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000029;
    border: 0.699999988079071px solid var(--primary-color);
    border-radius: 6px;
    padding: 4px 8px;
    margin-left: 16px;
    margin-top: 10px;
    color: var(--primary-color);
    font: normal normal normal 14px/19px Nunito Sans;
  }

  .chat-btn-active {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    color: #fff;
  }
}

//}

.doubts-list {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000029;
  border-radius: 0px 0px 6px 6px;
  min-height: calc(100vh - 258px);
  max-height: calc(100vh - 258px);
  overflow: auto;

  .refresh-i {
    height: 20px;
    width: 20px;
    margin: 10px;
  }

  .doubt-item {
    border-left: 4px solid #ffffff;
    border-top: 0.4px solid whitesmoke;
    padding: 10px 10px 0px 10px;
    cursor: pointer;
  }

  .hightlight-selected {
    border-left: 4px solid var(--primary-color);
    background-color: whitesmoke;
  }

  .prfl-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .prfl-name {
    margin: 0px;
    font: normal normal 600 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #3b3b3b;
  }

  .prfl-grade {
    margin: 0px;
    letter-spacing: 0px;
    font: normal normal normal 8px/11px Nunito Sans;
    letter-spacing: 0px;
    color: #afafaf;
  }

  .doubt-s {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin: 0px;
    font: normal normal normal 12px/16px Nunito Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
    margin-top: 5px;
  }

  .style-unseen {
    color: var(--primary-color);
  }

  .unseen-count {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    float: right;
    text-align: center;
    padding: 2px 12px;
    color: white;
    font: normal normal normal 11px/15px Nunito Sans;
    letter-spacing: 0px;
  }

  .pending-doubt {
    width: 18px;
    height: 18px;
    //float: right;
    margin-top: -6px;
  }
}



.chat-typing {
  padding: 10px;
  background-color: #e6f5fc;

  .chat-textarea {
    width: 100%;
    border-radius: 8px;
    border: none;
    padding: 8px;
    //line-height: 20px;
    resize: none;
    margin-left: 20px;
    margin-right: 20px;
    font: normal normal normal 16px/22px Nunito Sans;
  }

  .chat-send-i {
    float: right;
    color: var(--primary-color);
    // height: 36px;
    // width: 36px;
    width: 36px;
    height: 36px;
  }

  .chat-add-more {
    color: var(--primary-color);
    height: 36px;
    width: 36px;
    text-align: center;
  }

  .respond-now-w {
    width: 180px;
    cursor: pointer;

    .respond-now {
      cursor: pointer;
      margin-left: 10px;
      background: var(--primary-color) 0% 0% no-repeat padding-box;
      border-radius: 8px;
      border: none;
      padding: 7px 14px;
      color: white;
      font-size: 13px;

      font: normal normal normal 16px/20px Segoe UI;
      // width: 140px;
    }
  }

  .add-attachments {
    width: 24px;
    height: 24px;
  }
}

.report-dialog {
  padding: 10px;

  .report-title {
    font: normal normal normal 20px/24px Roboto;
    letter-spacing: -0.48px;
    color: #1d1d1d;
  }

  .report-reason {
    font: normal normal normal 16px/19px Roboto;
    letter-spacing: -0.38px;
    color: #929292;
  }

  .report-textarea {
    width: 100%;
    border-radius: 8px;
    border: none;
    padding: 6px;
    box-shadow: 0px 0.5px 2px #00000029;
    border: 1px solid #d6d6d6;
    margin-left: 10px;
    font: normal normal normal 12px/14px Roboto;
  }

  .err-msg {
    color: red;
    font-size: 12px;
  }
}

.no-doubts {
  text-align: center;
  margin-top: 20px;
  color: #999999;
  font-family: Nunito Sans;
}

// .ma-p10 {
//   padding-bottom: 4px;
//   background: #ffffff 0% 0% no-repeat padding-box;
// }

.desc-search-bar {
  //position: relative;
  //top: 0;

  margin: 6px;

  .search-input-w {
    margin: 4px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.5px 1px #00000029;
    border: 0.10000000149011612px solid #cccccc;
    border-radius: 7px;
    overflow: hidden;

    .search-icon {
      height: 20px;
      width: 20px;
      margin-top: 10px;
    }

    .search-input {
      width: 100%;

      padding: 8px;
      border: none;
    }
  }

  .filter-icon {
    align-items: center;
    margin: 0px 4px;
    cursor: pointer;
  }
}

.submit-btn {
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000036;
  border-radius: 6px;
}

.add-file-attach {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-top: -5px;
}

.send-msg-btn {
  background-color: inherit;
  border: none;
}

.search-filter {

  .form-group-filter {
    //min-width: 400px;
    z-index: 20;
    overflow-y: auto;
    max-height: 300px;

    // .cnt-lbl {
    //   display: block;
    // }
    //max-height: calc(100vh - 300px);
    //max-height: 500px;
  }

  .sort-title {
    font: normal normal bold 22px/30px Nunito Sans;
    letter-spacing: -0.53px;
    color: #1d1d1d;
    margin: 5px;
  }


  .sort-title:after {
    content: "";
    display: block;
    // margin: 0 auto;
    width: 50px;
    padding-top: 0px;
    border-bottom: 3px solid var(--primary-color);
  }

  .action-buttons {
    float: right;
  }

  .reset-btn {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    font: normal normal bold 12px/16px Nunito Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
    opacity: 1;
    padding: 4px 10px;
    background-color: white;
  }

  .done-btn {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    font: normal normal bold 12px/16px Nunito Sans;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 4px 10px;
    margin-left: 10px;
  }
}

.delete-wrapper {
  display: none;
  position: absolute;
  top: 0px;
  right: 4px;
  z-index: 12;

  .down-arrow {
    width: 16px;
    cursor: pointer;
  }
}

.ask-wr {
  position: relative;
}

.ask-wr:hover .delete-wrapper {
  display: block;
}

.ma-hide {
  display: none;
}

.delete-msg {
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: -0.34px;
  color: #929292;
  padding: 0px !important;

  li {
    padding: 5px 10px !important;
  }
}

.message-time {
  //  font-size: 8px;
  font: normal normal 500 9px/19px Nunito Sans;
}

.sent-icon {
  font-size: 12px !important;
}

// .ask-wr {
//   position: relative;
// }
// .ask-wr:hover .delete-wrapper {
//   display: block;
// }

// .ask-wr {
//   //float: right;
//   &:after {
//     // content: '';
//     // position: absolute;
//     // margin: -1.5em -17.06em;
//     // @include triangle(rgba(255, 255, 255, 0.2), 10, left);
//     content: "";
//     position: absolute;
//     top: 0;
//     width: 0;
//     height: 0;
//     border: 20px solid transparent;
//     border-bottom: 0;
//     margin-top: 0px;
//   }
// }

// .ma-left {
//   &:after {
//     left: 0;
//     border-right-color: var(--primary-color) !important;
//     border-left: 0;
//     margin-left: -20px;
//   }
// }

// .ma-right {
//   &:after {
//     right: 0;
//     border-left-color: #eee;
//     border-right: 0;
//     margin-right: -20px;
//   }
// }



.chat-c {
  //display: inline-block;
}



.delete-m-menu {
  padding: 10px;
  width: 120px;
}



.macro {

}

.msj-rta {

}

.msj {

}


.msj:before{
  width: 0;
  height: 0;
  content:"";
  top:-5px;
  left:-14px;
  position:relative;
  border-style: solid;
  border-width: 0 13px 13px 0;
  border-color: transparent var(--primary-color) transparent transparent;            
}
.msj-rta:after{
  width: 0;
  height: 0;
  content:"";
  top:-5px;
  left:14px;
  position:relative;
  border-style: solid;
  border-width: 13px 13px 0 0;
  border-color: var(--primary-color) transparent transparent transparent;           
}
.prfl-name1 {
    margin: 0px;
    font: normal normal 500 12px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #3b3b3b;
    line-height: 14px;
}