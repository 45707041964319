.widget-wrapper {
   // background-color: white;
    height: 100%;
    width: 100%;
    //border: 1px solid rgba(0, 0, 0, 0.2);
    overflow: auto;

    .menu-bar-wrapper {
        // position: relative;

    }

    .menu-bar {
        background-color: white;
        opacity: 0.5;
        visibility: hidden;

        .menu-bar-items {
            padding: 4px;
            height: 16px;
            margin-top: 10px;
            padding-right: 10px;

            span {
                float: right;
                cursor: pointer;
            }
        }


    }
}


.widget-wrapper:hover .menu-bar {
    visibility: visible;
}




.widget-s1 {
    background-color: red;
}

.layout {

    background-color: rgba($color: #000000, $alpha: 0.1);
}