.periods-graph {
    height: 340px;
    //mar-bottom: -30px;
}


.periods-graph-w {
    margin-top: 30px;
    width: 50%;
    background: var(--theme-100);

    box-shadow: 0 2px 1px -1px var(--shadow-80), 0 1px 1px 0 var(--shadow-90), 0 1px 3px 0 var(--shadow-95);
    border-radius: 16px;
    padding: 20px;


    .no-data {
        text-align: center;
        padding-top: 150px;
        font: normal normal 600 20px/27px Nunito Sans;
    }
}