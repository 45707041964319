.periods-wrapper {

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 20px 30px;
    align-items: center;


    .period-name {
        font: normal normal normal 19px/26px Segoe UI;
        letter-spacing: 0px;
        color: #848484;

    }


    .period-time {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #00000029;
        border: 0.5px solid #BEBDBD;
        border-radius: 6px;
        padding: 7px;
        text-align: left;
        font: normal normal normal 15px/20px Segoe UI;
        letter-spacing: 0px;
        color: #707070;


        .ca-icon {
            float: right;
        }
    }

    .period-break {
        background: var(--primary-color) 0% 0% no-repeat padding-box;
        border-color: var(--primary-color);
        color: white;

    }
}




.time-tbl {

    .tbl-headings {}
}


.period-wrapper {
    width: 200px;
}


.assing-teacher-title {
    font: normal normal bold 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
}


.periods-date {
    font: normal normal bold 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    display: inline-flex;
    margin: 0px;

    span {
        font: normal normal normal 14px/19px Segoe UI;
        letter-spacing: 0px;
        color: #707070;
        cursor: pointer;
        // margin-left: 10px;
        // margin-right: 10px;
    }

    i {
        position: relative;
        top: 5px;

        color: #635F5F;
        font-size: 24px;
    }
}

.ma-periods-table-wrapper {
    background-color: white;
    overflow: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 0px;
    margin-top: 30px;

    .section-info {
        width: 300px;
    }
    .ma-periods-table {
        border-collapse: collapse;
        width: 100%;
        background-color: white;
        overflow: hidden;

        .break-s {
            background-color: whitesmoke;
            color: #AE230D;
            writing-mode: vertical-rl;
            text-orientation: upright;
        }

        th {
            border: 1px solid #dddddd;
            font: normal normal 600 14px/19px Nunito Sans;
            letter-spacing: 0px;
            color: #545454;
            text-align: center;
            padding: 20px;
            margin: 10px;
        }


        .section-name {
            font: normal normal 600 14px/19px Nunito Sans;
            letter-spacing: 0px;
            color: #545454;
            text-align: center;
            width: 100%;
            word-break: break-all;
            overflow: auto;
        }

        td {
            border: 1px solid #dddddd;
            padding: 8px;
        }
    }
}

.time-lbl {
    font: normal normal 600 12px/16px Nunito Sans;
    letter-spacing: 0px;
    color: #545454;

}


.section-hierarchy {
    padding: 10px;


    .section-h {

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

    }
}