#login {
    font-family: 'Poppins', sans-serif;
    // background: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

#login body {
    background: #fff;
}

#login .border_total {
    border: 1px solid #ccc;
    background: #fff;
    padding: 30px 10px;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 50px;
    width: 30%;

}

#login .login_btn {
    width: 100%;
    padding: 10px;
    cursor: pointer;
}

#login label {
    font-size: 13px;
    font-weight: 600;
}



.input-1 {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}


.img-fluid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 50px;
}