.sub-sections {

    padding: 15px 15px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    margin: 10px 5px;

}



.sub-sections2 {

    padding: 5px 15px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    margin: 10px 5px;

}