.dashboard-card {
    // background: var(--theme-100) 0% 0% no-repeat padding-box;
    //box-shadow: 0px 3px 6px var(--shadow-85);
    box-shadow: 0px 3px 6px var(--shadow-80);
    border: 0.2px solid #d9d9d9;
    border-radius: 8px;
    padding: 4px 16px;
    // max-width: 70%;
    margin: auto;
    // height: 100px;
    background-repeat: no-repeat;
    background-position: right;

    .card-title {
        font: normal normal normal 13px/18px Nunito Sans;
        letter-spacing: 0px;
        color: white;


    }


    .ma-v-middle {
        position: relative;
        top: 30%;

    }

    .card-value {
        font: normal normal 600 28px/32px Nunito Sans;
        letter-spacing: 0px;
        color: white;
        margin: 0px;
    }

    .card-time {
        font: normal normal normal 10px/14px Nunito Sans;
        letter-spacing: 0px;
        color: white;
        margin-bottom: 5px;
    }
}

.dc-1 {
    background-color: #2E4DD1;
    background-image: url('./images/blue_card.png');

}

.dc-2 {
    background-color: #FFB032;
    background-image: url('./images/yellow_card.png');
}

.dc-3 {
    background-color: #E5346D;
    background-image: url('./images/Red_card.png');
}

.dc-4 {
    background-color: #424270;
    background-image: url('./images/Gray_card.png');
}


.resource-usage-report {
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-80);
    border: 0.2px solid #d9d9d9;
    border-radius: 8px;
    height: 209px;

    .resource-title {
        font-family: Nunito Sans;
        padding-left: 30px;
        margin-bottom: 0px;
        color: #000131;


    }
}

.resource-usage-discussion {
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-80);
    border: 0.2px solid #d9d9d9;
    border-radius: 8px;
    min-height: 209px;
    //  height: 200px;

    .resource-title {
        font-family: Nunito Sans;
        padding-left: 30px;
        margin-bottom: 0px;
        color: #000131;


    }
}

.trending-table-w {
    margin: 0px 20px;

    min-height: 140px;

    .trending-table {


        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;


        td,
        th {
            border-bottom: 1px solid #dddddd;
            text-align: left;
            padding: 5px;
            text-align: left;
            font: normal normal 300 12px/14px Roboto;
            letter-spacing: 0px;
            color: #000131;
        }

        .t-head-trend {
            font: normal normal bold 14px/16px Roboto;
            color: #02718afe;
        }
    }
}

.trending-table-rup {
    margin: 10px 20px;
    min-height: 130px;

    .trending-table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 80%;

        .t-head-v {
            font: normal normal bold 14px/16px Roboto;
            color: #0821f5;
        }

        .t-head-pdf {
            font: normal normal bold 14px/16px Roboto;
            color: #E5346D;
        }

        .t-head-ppt {
            font: normal normal bold 14px/16px Roboto;
            color: #077CB6;
        }

        .t-head-q {
            font: normal normal bold 14px/16px Roboto;
            color: #B80ADD;
        }

        td,
        th {
            border-bottom: 1px solid #dddddd;
            text-align: center;
            padding: 10px;
            font: normal normal 300 12px/14px Roboto;
            letter-spacing: 0px;
            color: #000131;
        }

        .color {
            font: normal normal bold 14px/16px Roboto;
            color: #9b6000;
        }

        .color2 {
            font: normal normal bold 14px/16px Roboto;
            color: #02a088;
        }
    }
}

.flex-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.ma-mr100 {
    margin-right: 100px;
}

.font-b {
    font: normal normal bold 14px/16px Roboto
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.p-dialog-dash {
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px var(--shadow-85);
    border: 0.05000000014901161px solid #d9d9d9;
    border-radius: 12px;
    // overflow: auto;
}

.p-overflow-hidden {
    overflow: auto;
}

.resource-usage-graph {
    margin-top: -20px;
    // height: 100%;
    height: 160px;
    // padding-bottom: 30px;
}

.usage-trend {

    margin-left: -85px;
    margin-top: 25px;

    .trend-title {
        font: normal normal bold 12px/14px Nunito Sans;
        margin-bottom: 5px;
        //  margin-top: 50%;
    }


    .trend-mark {
        font: normal normal bold 25px/30px Nunito Sans;
        color: #13BF13;
        margin: 0px;
    }
}

.ask-a-doubt-stats {
    background-color: #6C5DD3;
    padding: 24px 9px 24px 9px;
   
   
    
 

    .ask-adoubts-stats-w {
        background-color: white;
    }

    .ask-adoubts-stats {
        
        //  margin-left: -100px;
        //min-height: 209px;
        background-color: white;

    }


    .ask-title {
        color: white;
        font-family: Nunito Sans;
        margin-bottom: 0px;
        margin-top: 0px;

    }


    .ask-a-d-graph-w {

        background-color: white;
        width: 150px;
        height: 150px;

        margin-left: 10px;
        border-radius: 8px;

        svg {
            width: 180%;
            height: 180%;
            margin-left: -60px;
            margin-top: -60px;
        }
    }


    .doubt-label {
        font: normal normal normal 10px/12px Nunito Sans;
        color: white;
        display: inline-block;

        text-align: center;
        margin-bottom: 6px;

        span {
            height: 5px;
            border-radius: 5px;
            width: 5px;


        }

    }


    .doubt-value {
        font: normal normal bold 14px/16px Nunito Sans;
        //text-align: center;
        margin: 0px 0px 0px 14px;
        color: white;

    }

    .dot-open {
        display: inline-block;
        height: 10px;
        border-radius: 10px;
        width: 10px;
        background-color: #B80ADD;
        margin: 0px;
    }

    .dot-pending {
        display: inline-block;
        height: 10px;
        border-radius: 10px;
        width: 10px;
        background-color: #FFA2C0;
        margin: 0px;
    }

    .dot-closed {
        display: inline-block;
        height: 10px;
        border-radius: 10px;
        width: 10px;
        background-color: #0A95DD;
        margin: 0px;
    }
}

.discussion-board-stats {


    background-color: #FFB43C;

    // height: 300px;
    padding: 20px 9px 24px 9px;
    //min-height: 209px;

    .resource-title {
        color: white;
    }

    .stat-item {
        height: 100px;
        align-items: center;
        background-color: white;
        padding: 5px;
        border-radius: 10px;
        vertical-align: middle;
        text-align: center;


    }


    .t-thread {
        font: normal normal bold 10px/12px Roboto;
        letter-spacing: 0px;
        color: #222649;
        margin-bottom: 0px;
        margin-top: 28px;
    }


    .t-thread-v {
        font: normal normal bold 14px/16px Roboto;
        letter-spacing: 0px;
        color: #222649;
        margin: 3px;
        margin-bottom: 0px;
    }

    .t-thread-h {
        font: normal normal bold 10px/14px Roboto;
        letter-spacing: 0px;
        color: #39B10A;
        margin: 0px;

    }

    .s1 {
        color: #6C5DD3
    }

    .s2 {
        color: #E5346D
    }

    .s3 {
        color: #077CB6
    }

    .s4 {
        color: #B80ADD
    }

}

.no-notifications {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    /* Ensure it takes up full height of its container */
    color: rgb(61, 60, 60);
    /* Set text color to white */
    /* Optional: Set background color for better contrast */
    padding: 20px;
    /* Add some padding if needed */
    font: normal normal normal 15px/15px Roboto;
    text-align: center;
    /* Center text horizontally */
}

.notifications-dashboard {

    overflow: auto;
    max-height: calc(100vh - 0px);
    // min-height: calc(100vh - 200px);
    //height: 100%;
    background-color: #424270;
    box-shadow: 0px 3px 6px var(--shadow-80);
    border: 0.2px solid #d9d9d9;
    border-radius: 8px;
    padding: 4px 0px;
    // overflow: hidden;


    .notifi-w {
        overflow-y: auto;
        min-height: 100%;
        //   background-color: white;
        //   max-height: calc(100vh - 380px);
        //   min-height: calc(100vh - 380px);
        //
    }

    .n-d-title {
        text-align: center;
        font: normal normal normal 13px/18px Nunito Sans;
        color: white;

    }



    .notify-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 5px;


        .notify-type {
            font: normal normal normal 9px/12px Roboto;
            margin: 4px;
            color: whitesmoke;

            span {
                float: right;
                color: whitesmoke;
            }

        }


        .notify-item-title {
            text-overflow: ellipsis;
            word-break: break-all;
            font: normal normal normal 14px/18px Nunito Sans;
            margin: 4px;
            color: whitesmoke;

        }
    }
}


.notifications-dashboard-bba-org {

    overflow: auto;
    // max-height: calc(100vh - 40px);
    // min-height: calc(100vh - 40px);
    // height: 100%;
    background-color: #424270;
    box-shadow: 0px 3px 6px var(--shadow-80);
    border: 0.2px solid #d9d9d9;
    border-radius: 8px;
    padding: 4px 0px;
    // overflow: hidden;


    .notifi-w {
        overflow-y: auto;
        min-height: 100%;
        //   background-color: white;
        //   max-height: calc(100vh - 380px);
        //   min-height: calc(100vh - 380px);
        //
    }

    .n-d-title {
        text-align: center;
        font: normal normal normal 13px/18px Nunito Sans;
        color: white;

    }



    .notify-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 5px;


        .notify-type {
            font: normal normal normal 9px/12px Roboto;
            margin: 4px;
            color: whitesmoke;

            span {
                float: right;
                color: whitesmoke;
            }

        }


        .notify-item-title {
            text-overflow: ellipsis;
            word-break: break-all;
            font: normal normal normal 14px/18px Nunito Sans;
            margin: 4px;
            color: whitesmoke;

        }
    }
}

.trending-chapter {}