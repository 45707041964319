.attach-preview-d {
    position: relative;
    display: inline-block;

    img {
        height: auto;
    }

    .attach-remove {
        position: absolute;
        top: 8px;
        // right: 16px;
        font-size: 18px;
        cursor: pointer;
    }
}



.vidoes-linked {
    margin-top: 5px;

    .p-datatable {
        border: 1px solid rgba(0, 0, 0, 0.2)
    }
}



.question-in-tbl {


    .question-label {
        margin-top: 5px;
        margin-bottom: 5px;
        font: normal normal 600 14px/19px Nunito Sans;
        letter-spacing: 0px;
        color: #333333;

    }

    .key-value {
        margin-left: 10px;
    }

    .q-option-keys {
        font: normal normal 600 14px/19px Nunito Sans;
        letter-spacing: 0px;
        color: #333333;
        margin: 0px;
        margin-right: 10px;
    }


    .option-w {
        width: 50%;
        float: left;

        padding: 5px;
        .option {
            margin: 4px 15px 4px 50px;
            font-size: 18px;
        }
    }

    .q-attach {
        margin-left: 100px;
        padding: 10px;
        max-width: 20%;
    }

    .o-attach {
        margin-left: 60px;
        padding: 10px;
        // max-height: 200px;
        max-width: 40%;
    }

}


.question-preview {

    font-family: Poppins ;
}