.a-item {
    border: 2px solid #673AB7;
    border-radius: 10px;
    margin: 20px;
}

.a-item .a-h1 {
    padding: 10px;
    text-align: center;
    margin-bottom: 5px;

}

.a-item .a-count {
    text-align: center;
    font-size: 30px;
    font-weight: bold;

}

.sp-bar-paper {
    margin: 10px;
    padding: 20px;
    border-radius: 20px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;

}


.download-btn {
    right: 10px;
    top: 10px;
    background-color: #673AB7 !important;
    color: whitesmoke !important;
    padding: 6px 20px !important;
}

.analytics-c {
    padding: 10px;
}


.analytics-user {
    padding: 20px;

}



.u-name {
    color: #673AB7;
}


.total-analytics {
    text-align: center;
    background: #e7dcfc 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin-top: 15px;
    margin-bottom: 15px;

}


.total-analytics .heading {
    letter-spacing: 0px;
    color: #2A2A2A;
    opacity: 1;
    font-size: 15px;
    margin: 0px;

}

.total-analytics .count {
    letter-spacing: 0px;
    color: #2A2A2A;
    opacity: 1;
    font-size: 25px;
    font-weight: bold;

}

.analytics-row {
    margin: 10px !important;
}

.border-s {
    border-right: 1px solid #707070;

}

.total-count {

    margin-top: 15px;
}

.total-count .count {
    color: #FF447C !important;
    font-weight: bold;
    font-size: 18px;
}


.filter-actions {

    margin-top: 5px;
}


.filter-s {

    display: inline-block !important;
    padding: 20px;
}


.filter-s .time-button {
    margin: 10px;


}

.resource-type-dp {
    margin: 10px;
}


.active-btn {

    background-color: #673AB7 !important;
    color: white !important;
}


.analytics-filters {
    margin-top: 16px;
}






/* table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th, td {
    padding: 5px;
    text-align: left;    
  } */


.adhoc-report-tbl {

    border: 0.20000000298023224px solid #cecbcb;
    // border: 0.20000000298023224px solid #f0eded;
    margin-top: 20px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;

    div {
        overflow: auto;
        max-height: 60vh;
    }

    .exam-tbl {
        background: #FFFFFF 0% 0% no-repeat padding-box;

        border-radius: 12px;
        width: 200%;
        overflow: hidden;
        border-collapse: collapse;
        text-align: center;


        th {
            padding: 6px;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            font: normal normal 800 14px/19px Nunito Sans;
            letter-spacing: 0px;
            color: var(--primary-color);
            border: 0.20000000298023224px solid #707070;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-top: none;
            border-left: none;
            border-collapse: collapse;
            text-align: center;
            background-color: var(--theme-95);


        }

        .sno {
            width: 30px;
        }

        .mobile-number {
            width: 180px;
        }

        .phone-number {
            cursor: pointer;
        }

        td {

            padding: 10px;
            font: normal normal normal 12px/16px Nunito Sans;
            letter-spacing: 0px;
            color: #333333;
            border: 0.20000000298023224px solid #707070;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-collapse: collapse;
            text-align: center;
            border-top: none;
            border-left: none;

        }



    }

}






.analytics-type-btn {
    margin: 10px;
    height: 100px;

}

.sp-ml20 {
    margin-left: 20px !important;
}

.sp-ml30 {
    margin-left: 35px !important;
}


.sp-m30 {
    margin-left: 35px !important;
}


.test-active-btn {
    /* background-color: #6610F2 !important; */
    /* background-color: #E1C7FF; */
    /* color: white !important; */
    box-shadow: 0 4px 8px 0 #E1C7FF;
    transition: 0.3s;

}



.graph-c {

    margin-top: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.20000000298023224px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden;
    border-radius: 12px;


    .questions-graph {
        height: 600px;
        width: 92%;
    }
}




.sp-accord {
    font-weight: bold;
    font-size: 18px;
    margin: 10px;
}


.exam-analytics-tabs {

    .e-btn {
        background-color: white;

    }
}

.question-graph-tbs {

    .p-tabview-panels {
        display: none;
    }



}


.exam-stats {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 10px;

    margin-left: 20px;
    //margin-right: 10px;

    p {
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #848484;
        opacity: 1;
        margin: 5px;
    }

    p.marks {
        margin: 5px;
        text-align: right;
        font: normal normal normal 24px/33px Open Sans;
        font-weight: bold;
        letter-spacing: 0px;
    }
}

.highest {
    border: 0.5px solid #20C2C8;

    p.marks {
        color: #20C2C8;
    }
}


.average {
    border: 0.5px solid #FFDA83;

    p.marks {
        color: #FFDA83;
    }
}

.user {
    border: 0.5px solid #FF8373;

    p.marks {
        color: #FF8373;
    }
}



.report-c {
    margin-top: 20px;

    .report-title-c {}

    .report-title {
        text-align: left;
        font: normal normal bold 18px/24px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
        margin: 5px;
    }

    .report-exam-name {
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
        text-transform: capitalize;
    }

    .report-stats {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 4px 6px 6px #0000000a;
        border: 1px solid #caced5;
        opacity: 1;
        height: 80px;
        // margin: auto;
        //  max-width: 80%;
        border-radius: 10px;
        overflow: hidden;
        padding: 10px;
        /* width: 183px; */
    }

    .stats-icon {
        width: 55px;
        height: 55px;

        opacity: 1;
        border-radius: 50%;

        .stats-img1 {
            padding: 14px;
        }

        .stats-img2 {
            padding: 21px 15px;
        }

        .stats-img3 {
            padding: 14px 18px;
        }

        .stats-img4 {
            padding: 14px;
        }
    }

    .stat-title {
        letter-spacing: 0px;
        color: #6a7284;
        opacity: 1;
        text-align: left;
        font: normal normal normal 15px/25px Roboto;

        span {
            //  float: right;
            text-align: left;
            font: normal normal bold 14px/25px Source Sans Pro;
            letter-spacing: 0px;
            color: #3cc480;
            opacity: 1;
            margin-left: 10px;
        }
    }

    .stats-icon1 {
        background: #4981fd57 0% 0% no-repeat padding-box;
    }

    .stats-icon2 {
        background: #efe3e7 0% 0% no-repeat padding-box;
    }

    .stats-icon3 {
        background: #4981fd57 0% 0% no-repeat padding-box;
    }

    .stats-icon4 {
        background: #4dcef257 0% 0% no-repeat padding-box;
    }

    .stat-count {
        font: normal normal bold 25px/33px Source Sans Pro;
        letter-spacing: 0px;
        color: #3cc480;
        opacity: 1;
        margin-bottom: 0px;
    }

    .stat-count1 {
        color: #3cc480;
    }

    .stat-count2 {
        color: #ff4141;
    }

    .stat-count3 {
        color: #3a49be;
    }

    .stat-count4 {
        color: #1685a3;
    }

    .btn-r {
        padding: 8px 20px 8px 30px;
        border-radius: 7px;
        font: normal normal normal 17px/26px Roboto;
        //font-weight: bold;
        letter-spacing: 1.02px;
        color: #ffffff;
        opacity: 1;
        text-transform: capitalize;
        font-size: medium;
        width: 80%;
        height: 47px;
        margin-bottom: 4px;
        //margin: auto;
        border: none;

        .arrow-icon {
            //margin-left: 26px;
            float: right;
            margin-right: 10px;
            margin-top: 4px;
        }
    }

    .btn-r1,
    .btn-r1:hover,
    .btn-r1:focus {
        background-color: #2b42cf;
    }

    .btn-r2,
    .btn-r2:hover,
    .btn-r2:focus {
        background-color: #34315d;
    }

    .btn-r3,
    .btn-r3:hover,
    .btn-r3:focus {
        background-color: #778ed7;
    }

    .btn-r:not(:first-child) {
        margin-top: 10px;
    }
}

.time-management-c {
    margin-left: 10px;
    border: 1px solid #707070;
    border-radius: 10px;

    .time-management {
        .time-c {
            position: relative;
        }

        margin: 0px 50px;

        .exam-tbl {
            width: 100%;
            margin-top: -1px;
            color: #6a6a6a;
            font-weight: 600;
            font-size: 12px;
            text-transform: capitalize;
            border-collapse: collapse !important;
            border-radius: 18px 18px 0px 0px !important;
            overflow: hidden !important;
        }

        table.exam-tbl th {
            /* background-color: #F5F6FA; */
            padding: 5px;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 17px;
            // border-left: 1px solid rgba(0, 0, 0, 0.2);
            // border-right: 1px solid rgba(0, 0, 0, 0.2);
        }

        .time-m-title {
            font: normal normal normal 21px/20px Roboto;
            letter-spacing: 0px;
            color: #4d4f5c;
            opacity: 1;
            border-collapse: collapse;
        }

        .t-highlights {
            z-index: 10;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            border-radius: 16px 14px 16px 16px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 5px;

            .time-m-stats {
                text-align: center;
                padding: 10px 15px;
                margin: 0px;
                display: inline-flex;
                align-items: center;
                color: #6a6a6a;
                font-size: 12px;

                .count {
                    font-size: 30px;
                    font-weight: bold;
                    margin-left: 5px;
                }

                .count-0 {
                    color: #17b705;
                }

                .count-1 {
                    color: #ffd710;
                }

                .count-2 {
                    color: #eb5037;
                }

                .count-3 {
                    color: #0540b7;
                }

                .count-4 {
                    color: #cf1d4e;
                }
            }
        }

        .exam-tbl tr:first-child th {
            padding: 0px !important;
            border-block-end-color: white !important;
            border-block-start-color: white !important;
            position: relative !important;
        }
    }
}

/* .time-management table.exam-tbl, */

//.time-management table.exam-tbl,
//.time-management table.exam-tbl th,
.time-management table.exam-tbl td {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-collapse: collapse;
    text-align: center;
}

.time-management th,
.time-management table.exam-tbl td {
    padding: 5px 10px;
    /* background-color: white; */
    font-size: 12px;
}

.time-management table.exam-tbl td {
    font-weight: 500;
    padding: 10px;
}

.time-management table.exam-tbl .phone-number {
    color: #00118b;
    font-weight: bold;
}

.subjects-table {
    border: 1px solid #34315d;
    width: 100%;
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;

    tr:nth-child(1) {
        background-color: #34315d;
    }

    tr th:first-child {
        padding-left: 20px !important;
    }

    th {
        color: white;
        font-size: 15px;
        font-weight: 500;
        // text-align: center;
    }

    th:not(:first-child) {
        text-align: center;
    }

    td:not(:first-child) {
        text-align: center;
    }

    td {
        color: gray;
    }

    td,
    th {
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-transform: capitalize;
        border-collapse: collapse;
        overflow: hidden;
        // overflow: hidden;
    }

    tr td:first-child {
        padding-left: 20px;
    }
}

.comparative-analysis {
    margin-top: 20px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    //display: inline-block;
    border-radius: 15px;

    .comparative-title {
        font-size: 22px;
        padding-top: 20px;
        color: #484848;
        text-align: center;
        font-weight: bold;
        text-transform: capitalize;
    }

    .comparativ-bar {
        height: 300px;
        padding: 20px;
        margin-top: -70px;
    }
}

.breakdown-analysis {
    margin: 20px 0px;

    .b-title {
        // font-size: 19px;
        //font-weight: 500;
        letter-spacing: 0px;
        opacity: 0.66;
        font: normal normal bold 19px/26px Nunito Sans;
    }

    .b-times {
        color: #0030ef;
        font-size: 12px;
    }

    .b-c-title {
        //  text-align: center;
        //  display: inline-flex;
        align-items: center;
        color: #8a8a8a;
        font-size: 14px;
        margin-top: 10px;
    }

    .b-count {
        float: right;
        font: normal normal bold 26px/35px Nunito Sans;
        letter-spacing: 0px;
        color: #252525;
        opacity: 1;
        margin-top: -10px;
    }

    .attempted-c {
        color: #252525;
    }

    .overtime-c {
        color: #2c67e8;
    }

    .perfect-c {
        color: #6bf9e2;
    }

    .wasted-c {
        color: #16bcea;
    }
}

.time-slot-c {
    margin: 20px 0px;

    .time-slot-w {
        width: 50%;
        float: left;

        .time-slot {
            border: 1px solid grey;
            border-radius: 7px;
            opacity: 1;
            font: normal normal normal 12px/16px Nunito Sans;
            letter-spacing: 0px;
            color: grey;
            opacity: 1;
            padding: 8px;
            margin: 10px;
            width: 120px;
            height: 32px;

            .t-active {
                background-color: #00ff55;
                border-color: #00ff55 !important;
            }

            .t-btn {
                width: 8px;
                height: 8px;
                border: 1px solid gray;
                border-radius: 50%;
                display: inline-block;
            }
        }

        .t-b-active {
            color: #0030ef;
            border-color: #0030ef;
        }
    }
}

// .line-chart {
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 4px 10px 6px #0000000a;
//     border: 1px solid #caced5;
//     opacity: 1;
//     text-align: center;
//     border: 1px solid grey;
//     border-radius: 18px;
//     overflow: hidden;

//     //margin-bottom: 20px;
//     .line-chart-c {
//         margin-top: 20px;
//         height: 300px;
//         margin-bottom: 20px;
//     }

//     .form-control {
//         //  width: 100%;

//         .text-field {
//             margin: 30px 10px;
//             width: 240px;
//             margin-bottom: 0px;
//             height: 2rem;
//         }

//         .MuiMenuItem {
//             font: normal normal normal 10px/14px Nunito Sans;
//             letter-spacing: -0.24px;
//             color: #000000;
//             opacity: 0.6;
//             padding: 3px 16px;


//         }
//     }
// }



.individual-c-a {
    .p-dialog-title {
        text-align: left !important;
        color: #6a6a6a !important;
    }
}


.question-item {
    cursor: pointer;

}


.question-item:hover {
    cursor: pointer;
    background-color: whitesmoke;

}


.behavior-analytics {


    .time-accuracy {
        margin: 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 20px;
        width: 80%;
        //   margin: auto;
    }

    .behavior-title {
        font: normal normal bold 22px/18px Roboto;
        letter-spacing: 0px;
        color: #4D4F5C;
        text-align: center;
    }

    .t-accuracy {
        font: normal normal normal 13px/9px Roboto;
        letter-spacing: 0px;
        color: #4D4F5C;
        text-align: center;
    }




    .filter-btn {
        padding: 0.2rem 0.3rem;
        border-radius: 4px;
        font-size: 10px;
        width: 80px;

    }


    .frequency-wise {


        .fr-label {
            font: normal normal 600 8px/13px Segoe UI;
            letter-spacing: 0.6px;
            color: #252525;
            text-transform: uppercase;
            opacity: 0.83;
            margin: 0px;
        }

        .fr-value {
            margin: 0px;
            font: normal normal 600 18px/22px Segoe UI;
            letter-spacing: 0px;
            color: var(--primary-color)
        }


        .correct {
            color: #17B705;

        }

        .wrong {
            color: #EB5037
        }


    }

}


.download-graph {
    background-color: white;
    padding: 20px;


    @media print {
        .page-break {
            page-break-after: always;
        }

        .p-md-6 {
            width: 50% !important;
        }

        .hours-graph-pie div {
            margin-left: -43px;

        }

        // .hours-graph-bar {
        //     width: 70%;

        // }

    }



    .each-subject {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
    }

    .h-heading {

        text-align: center;
    }

    .questions-graph {
        height: 500px;
        width: 65%;
    }


}


.hidden-print {
    visibility: 'hidden';
    max-height: '0px';
    height: 10px;
    overflow: scroll;
}