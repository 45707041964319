.preview-quiz {
    .exam-name {
        font: normal normal 600 16px/22px Nunito Sans;
        letter-spacing: 0px;
        color: #E28900;
    }


    .exam-info {


        .e-label {
            font: normal normal normal 12px/16px Poppins;
            letter-spacing: 0px;
            text-align: center;
            color: #B4B4B4;
        }

        .e-value {
            font: normal normal 600 16px/22px Nunito Sans;
            color: #E28900;
            text-align: center;
            line-height: 24px;
        }

    }


}


.selected-q-count {
    font: normal normal normal 18px/30px Poppins;
    letter-spacing: 0.11px;
    color: #171725;

    span {
        font: normal normal medium 20px/30px Poppins;
        letter-spacing: 0.13px;
        color: var(--primary-color);
    }
}


.quiz-info-assign {}



.exam-preview {
    .exam-name {
        font: normal normal 600 16px/22px Nunito Sans;
        letter-spacing: 0px;
        color: #E28900;
        //margin-top: 5px;
    }


    .edit-ic {
        color: black;
    }


    .e-label-2 {
        font: normal normal normal 12px/16px Poppins;
        letter-spacing: 0px;
        color: #B4B4B4;
    }

    .e-label {
        font: normal normal normal 12px/16px Poppins;
        letter-spacing: 0px;
        text-align: center;
        color: #B4B4B4;
    }

    .e-value {
        font: normal normal 600 16px/22px Nunito Sans;
        color: #E28900;
        text-align: center;
        line-height: 24px;
    }



}



.subject-l {
    margin-top: 8px;
    font: normal normal 600 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #545454;
}



.adhoc-q-t {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;


    .adhoc-q-selection {
        height: 40px;


        .q-t-label {
            font: normal normal 600 14px/19px Nunito Sans;
            letter-spacing: 0px;
            color: #171717;
            min-width: 50px;

            display: inline-block;
        }

        .q-tl2 {
            min-width: 90px;

        }

        .q-t-counts {

            margin-left: 8px;
            padding: 5px 10px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 2px #00000029;
            border: 1px solid #979797;
            border-radius: 6px;

            font: normal normal 600 12px/16px Nunito Sans;
            letter-spacing: 0px;
            color: var(--primary-color);
            min-width: 50px;
            display: inline-block;
            text-align: center;
        }



    }

    .add-questions {
        margin: 10px 0px;
    }


}

.adhoc-q-select-c {
    font: normal normal 600 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #171717;
    margin: 5px;


    span {
        color: var(--primary-color);

    }

}


.adhoc-subjects-tabs {


    .p-tabview-nav {

        li {
            .p-tabview-nav-link {
                padding: 0.5rem;
            }
        }

        overflow: auto;
    }
    

    .p-highlight a {
        background-color: var(--primary-color) !important;
        color: white !important;
        border-radius: 5px !important;
        opacity: 1 !important;
    }

    .p-unselectable-text {

        margin: 8px;

        a {
            font: normal normal normal 14px/19px Nunito Sans;
            letter-spacing: 1px;
            background-color: var(--primary-color) !important;
            color: white !important;
            border-radius: 5px !important;
            opacity: 0.3;


        }
    }

}

.preview-subjective {


    h2 {
        font-family: Poppins;
        color: var(--shadow-45);
    }


    label {
        font-family: Poppins;
        font-size: 13px;
        color: var(--shadow-45);
    }

    p {
        font-family: Poppins;
        color: var(--shadow-25);
        margin: 2px;
    }



}

.ex-instr {
    font-family: Poppins;
    font-size: 13px;
    color: var(--shadow-45);
}


.e-instructions-w {
    font-family: Poppins;
    font-size: 13px;
    color: var(--shadow-45);

    p {
        font-size: inherit;
        font-family: inherit;
        color: inherit;

    }
}