.bulk-attendace-u {
    border: 1px solid var(--shadow-80);
    padding: 10px;
    background-color: white;
    border-radius: 16px;
}


.attendance-report {

    border: 0.20000000298023224px solid #cecbcb;
    // border: 0.20000000298023224px solid #f0eded;
    margin-top: 20px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;


    .strength-highlight {
        cursor: pointer;
        font-weight: bold;

    }

    div {
        overflow: auto;
        max-height: 60vh;
    }

    .exam-tbl {
        background: #FFFFFF 0% 0% no-repeat padding-box;

        border-radius: 12px;
        min-width: 100%;
        overflow: hidden;
        border-collapse: collapse;
        text-align: center;


        th {
            padding: 6px;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            font: normal normal 800 14px/19px Nunito Sans;
            letter-spacing: 0px;
            color: var(--primary-color);
            border: 0.20000000298023224px solid #707070;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-top: none;
            border-left: none;
            border-collapse: collapse;
            text-align: center;
            background-color: var(--theme-95);
            min-width: 120px;


        }

        .sno {
            width: 30px;
        }

        .mobile-number {
            width: 180px;
        }

        .phone-number {
            cursor: pointer;
        }

        td {

            padding: 10px;
            font: normal normal normal 12px/16px Nunito Sans;
            letter-spacing: 0px;
            color: #333333;
            border: 0.20000000298023224px solid #707070;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-collapse: collapse;
            text-align: center;
            border-top: none;
            border-left: none;

        }

        td.grand-total {
            font-weight: bold;
            font-family: Nunito Sans;
        }


    }

}