.ml-10 {
    margin-left: 10%;
}

.ml-5 {
    margin-left: 5%;
}

.mr-5 {
    margin-right: 5%;
}

.ml-7 { 
    margin-left: 8%;
}

.flex{
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.justify-center{
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}
.w-auto {
    width: auto;
}
.w-10 {
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-50 {
    width: 50%;
}
.w-60 {
    width: 60%;
}
.w-70 {
    width: 70%;
}
.w-80 {
    width: 80%;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}
.inputNumberStyle {
    padding: 0.35rem 0.5rem;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;

    input {
        padding: 0;
        border: 0px solid var(--theme-85);
    }
}
.subject-add-dialog {
    min-width: 340px;
}

.qb-active-tab {
    cursor: pointer;
    font: normal normal normal 14px/21px Poppins;
    font-weight: 600;
    letter-spacing: 0.09px;
    color: #171725;
    margin-right: 20px;
    padding-bottom: 4px;
    border-bottom: 3px solid var(--primary-color);
}

.qb-inactive-tab {
    cursor: pointer;
    font: normal normal normal 15px/23px Poppins;
    letter-spacing: 0.09px;
    color: #BCBCBC;
    margin-right: 20px;
}

.ma-table-title {
    margin-left: 30px;
    // color: var(--shadow-10);
    // font: normal normal 600 23px/34px Poppins;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0.14px;
    color: #171725;
}

.radio-Buttons {
    margin-left: 30px;
    margin-top: 1%;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0.14px;
    color: #171725;
}

h3,p {
    margin: 2px;
}

.w-90 {
    width: 90%;
}

.toolbar {
    margin-left: 8%;
}

.backInFeeDetails {
    display: flex;
    flex-direction: row-reverse;
}

.pointer {
    cursor: pointer;
}

.vl{
    border-left: 1px dashed;
    height: 620px;
}

@media print {
    h2{
        font-size: 15px;
    }
    h3{
        font-size: 12px;
    }
    h5{
        font-size: 10px;
        margin: 1%;
    }
     
}