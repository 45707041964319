.enquiry-header {
    margin-left: 5px;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0.14px;
    color: #171725;
}

.receipt-image {
    padding: 30px 60px;
}

.receipt-heading {
    justify-content: center;
    text-align: center;
    
}
.heading-font {
    margin: 0 auto;
    letter-spacing: 0.14px;
    color: #171725;
    }

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.dashed-line {
    border: 2px dashed rgb(4, 4, 4);
  }


.upload-pdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.uploaded-image {
    width: 25%;
    height: 80%;
}
.subject-add-dialog  {
    margin-top: 10px;
    .p-inputtext-style1 {
        max-width: 92%;
    }
}

.view__fee-btn {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}