.create-org-w {
    min-height: 70vh;

    .logo-rect-text {
        font: normal normal normal 10px/14px Nunito Sans;
        letter-spacing: 0px;
        color: #BEBEBE;

    }

    .create-org-title {
        font: normal normal normal 23px/34px Poppins;
        letter-spacing: 0.14px;
        color: #171725;
        opacity: 0.8;
        font-weight: 600;
        margin-top: 0px;
    }

    .craete-org-subtitle {
        font: normal normal normal 16px/25px Poppins;
        letter-spacing: 0.1px;
        color: var(--primary-color);
        opacity: 0.8;
        font-weight: 600;
        margin-bottom: 5px;

    }
}


.org-steps {
    width: 50%;
    // display: inline-block;
    float: right;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 20px;
}


.ma-h-title {
    font: normal normal 500 23px/34px Poppins;
    letter-spacing: 0.14px;
    color: var(--primary-color);
    font-weight: 600;
    margin-top: 0px;
    //margin: 0px;
    //margin-bottom: 10px;
}

.modules-list {
    margin-left: 20px;

    .module-item {
        margin: 10px;
    }
}


.field-alert {
    margin-top: 4px;
    font: normal normal normal 9px/12px Nunito Sans;
    letter-spacing: 0px;
}


.domain-label {
    margin-top: 5px;
    margin-left: 3px;
    font: normal normal 600 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #545454;
}



.rect-logo {
    width: 160px;
    height: 40px;
}


.sqaure-logo {
    width: 40px;
    height: 40px;
}




.attach-link {
    font: normal normal 600 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #545454;
    text-decoration: none;
}



.start-1::before {
    right: 0;
    left: auto !important;
    width: 50% !important;
}

.end-1::before {
    width: 50% !important;
}


.assign-master {
    color: var(--primary-color);
    font: normal normal normal 23px/34px Poppins;
    font-weight: 600;
    letter-spacing: 0.14px;
}


.config-txt {
    font: normal normal normal 13px/20px Poppins;
    color: var(--shadow-10);
    margin: 2px;
} 