#summary .mark-section {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

#summary .marks {
  max-width: 46%;
  margin: 1% 2%;
  border: 1px solid #3A49BE;
  border-radius: 10px;
  padding: 0.8rem !important;
  height: auto !important;
}

#summary .subject-title {
  font: normal normal 600 18px/24px Nunito Sans;
  letter-spacing: 0px;
  color: #666666;
  border-bottom: 1px solid #7070703d;
  padding-bottom: 0.5rem;
  margin-top: unset;
}

#summary .mark-acheived {
  font: normal normal 600 18px/24px Nunito Sans;
  color: #3A49BE;
  padding: 0 0 0.5rem 0;
}

#summary .mark-category-section {
  border-right: 1px solid #7070703d;
  padding-right: 10%;
  padding-left: 1%;
}

#summary .mark-category-section:last-child {
  border-right: none;
}

#summary .mark-category-title {
  font: normal normal 600 15px/20px Nunito Sans;
  color: #5F5F5F;
  padding-bottom: 0.5rem;
}

#summary .marks-title {
  font: normal normal normal 12px/16px Nunito Sans;
  color: #707070;
  opacity: 0.7;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.2rem;
}

#summary .marks-value {
  font: normal normal 600 14px/19px Nunito Sans;
  color: #707070;
  /* margin-left: 1rem; */
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  color: #6A7284;
  opacity: 1;
}

