.attachment-icon-w {
    position: relative;
    text-align: center;
    //color: white;
    padding: 10px;
    width: 60%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.5px 1px #00000029;
    border: 0.25px solid #E4E4E4;

    .ma-top-right {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }

    .ma-attch-image {
        //width: 100%;
        max-height: 300px;
    }

    .ma-attch-video {
        width: 100%;
    }

    .ma-attch-audio {
        width: 80%;
    }
}


.ma-attachment-s1 {
    background: #F5F5F5 0% 0% no-repeat padding-box;

    box-shadow: 0px 2px 3px #00000029;
    width: 100%;
    overflow: hidden;
    padding: 6px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;

    .ma-attach-icon {
        width: 14px;
    }

    p {
        margin: 0px;
        padding: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        font: normal normal 600 11px/15px Nunito Sans;
        letter-spacing: -0.26px;
        color: #494848;

        opacity: 1;}


}